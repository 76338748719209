<template>
  <v-dialog
    max-width="800px"
    v-model="status"
    scrollable
    persistent
    :overlay="false"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title>
        {{ locationGroup.name }}
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog">close</v-icon>
      </v-card-title>
      <v-card-subtitle class="pb-0"
        >Add/Remove location from groups</v-card-subtitle
      >
      <v-text-field
        v-model="search"
        class="px-6"
        name="search"
        prepend-inner-icon="search"
        label="Search users"
      ></v-text-field>
      <v-card-text>
        <v-data-table :headers="headers" :items="locations">
          <template v-slot:item.active="{ item }">
            <v-checkbox
              v-model="item.is_active"
              @change="toggleLocation(item, $event)"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" color="primary">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AuthAPI } from '@/clients/auth/auth.api'
import { LocationsAPI } from '@/clients/locations/locationsapi'
export default {
  mounted() {
    this.loadGroup()
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name', align: 'left', sortable: true },
        { text: this._is50c ? 'Client Number' : 'Address', value: this._is50c ? 'display_name' : 'address.formatted_address', align: 'left', sortable: true },
        { text: 'Slug', value: 'slug', align: 'left', sortable: true },
        { text: 'Status', value: 'active', align: 'center', sortable: true }
      ],
      locations: [],
      search: ''
    }
  },
  props: {
    locationGroup: {
      type: Object,
      required: true
    },
    status: {
      default: false
    },
    group: {
      default: false
    }
  },

  methods: {
    closeDialog() {
      this.$emit('close-users-dialog')
    },
    async loadGroup() {
      this.spinner(true)
      let response
      try {
        response = await LocationsAPI.getLocationGroupWithLocations(this.locationGroup.id)
      } catch (error) {
        console.log(error)
        return
      }
      this.locations = response.data.payload.locations
      this.spinner(false)
    },

    async toggleLocation(location, event) {
      if (event) {
        this.addLocationToGroup(location, event)
      } else {
        this.removeUserFromLocation(location, event)
      }
    },
    async addLocationToGroup(location, event) {
      try {
        await LocationsAPI.addLocationToLocationGroup(this.locationGroup.id, location.id)
      } catch (error) {
        console.log(error)
        location.active = !event
      }
    },
    async removeUserFromLocation(location, event) {
      try {
        await LocationsAPI.removeLocationFromLocationGroup(this.locationGroup.id, location.id)
      } catch (error) {
        console.log(error)
        location.active = !event
      }
    }
  }
}
</script>

<style>
</style>
